import moment from "moment"

export function toStringDatetime(val) {
    return val != null && val != '' ? moment(val).format("DD/MM/YYYY HH:mm") : '';
}

export function toStringDate(val) {
    return val != null && val != '' ? moment(val).format("DD/MM/YYYY") : '';
}

export function toStringTime(val) {
    return val != null && val != '' ? moment(val).format("HH:mm") : '';
}

