<template>
  <card-form>
    <div class="row mt-4">
      <f-input 
        name="lote" 
        v-model="model.lote" 
        label="Lote"
        :disabled="true"
        :cols="2" />
      <f-input 
        name="dataFechamento" 
        :value="toStringDatetime(model.dataFechamento)" 
        label="Data de Fechamento"
        :disabled="true"
        :cols="2" />
      <f-input 
        name="hospital" 
        v-model="model.hospital" 
        label="Hospital"
        :disabled="true"
        :cols="4" />
      <f-input 
        name="convenio" 
        v-model="model.convenio" 
        label="Convênio"
        :disabled="true"
        :cols="4" />
    </div>

    <div class="row">
      <f-input 
        name="status" 
        :value="translateStatusLote(model.status)" 
        label="Status"
        :disabled="true"
        :cols="2" />
      <f-input 
        name="uploadData" 
        :value="toStringDatetime(model.uploadData)" 
        label="Data de Upload"
        :disabled="true"
        :cols="2" />
      <f-input
        name="auditadoPor" 
        :value="model.auditadoPor" 
        label="Auditado por"
        :disabled="true"
        :cols="3" />
      <f-input 
        name="dataUltimaAlteracao" 
        :value="toStringDatetime(model.dataUltimaAlteracao)" 
        label="Última Alteração"
        :disabled="true"
        :cols="2" />
    </div>

    <div class="row">
      <f-input 
        name="valorTotal" 
        :value="$options.filters.toCurrency(model.valorTotal)" 
        label="Valor"
        :disabled="true"
        :cols="2" />
      <f-input 
        name="glosa" 
        :value="$options.filters.toCurrency(model.glosa)" 
        label="Total Glosa"
        :disabled="true"
        :cols="2" />
      <f-input 
        name="valorFinal" 
        :value="$options.filters.toCurrency(model.valorFinal)" 
        label="Valor Final"
        :disabled="true"
        :cols="2" />

        <div class="col-md-6">
          <fieldset class="form-group mb-3 form-group-input">
            <legend tabindex="-1" class="bv-no-focus-ring col-form-label pt-0" style="color: white;">.</legend>
            <div class="d-flex" style="gap: 10px;">
              <!-- TODO: authorize -->
              <b-button pill variant="success" @click="exportar">
                <i class="uil uil-file-download-alt"></i>
                Download XML
              </b-button>
              
              <!-- TODO: authorize -->
              <b-button pill variant="success" @click="openModalRepresentante()">
                <i class="uil uil-file-graph"></i>
                Relatório de auditoria
              </b-button>
              
              <div class="d-flex mt-2" style="gap: 10px;">
                <!-- TODO: authorize -->
                <b-form-checkbox
                  id="relatorio-auditoria-glosa"
                  v-model="optRelatorioAuditoria.glosa"
                >
                  Glosa
                </b-form-checkbox>

                <!-- TODO: authorize -->
                <b-form-checkbox
                  id="relatorio-auditoria-opme"
                  v-model="optRelatorioAuditoria.opme"
                >
                  OPME
                </b-form-checkbox>
              </div>
            </div>
          </fieldset>
        </div>
    </div>

    <h4>Guias</h4>
    <div class="table-responsive">
      <b-table 
          striped hover small 
          :items="model.guias" 
          :fields="colunas">

        <template #cell(valorTotal)="data">
          {{ data.item.valorTotal | toCurrency }}
        </template>
        <template #cell(glosa)="data">
          <span v-if="data.item.glosa > 0">
            {{ data.item.glosa | toCurrency }}
          </span>
        </template>
        <template #cell(valorFinal)="data">
          {{ data.item.valorFinal | toCurrency }}
        </template>
        <template #cell(status)="data">
          {{ translateStatusAuditoria(data.item.status, data.item.statusComAlteracao) }}
        </template>

        <template #cell(action)="data">
          <b-button-group size="sm">
            <b-button
              variant="outline-primary"
              title="ver"
              v-authorize="'lotesScLotes'"
              @click="$router.push({
                name: 'faturamento.lotes.detalheguia.visualizar',
                params: {
                  arquivoId: model.id,
                  guiaIndex: data.item.index,
                  numeroLote: model.lote,
                },
              })"
            >
              <i class="uil uil-eye icon-size"></i>
            </b-button>

            <b-button v-if="editavel && data.item.status != 'A'"
              variant="outline-primary"
              title="Auditar"
              small
              v-authorize="'lotesScLotesAuditar'"
              @click="$router.push({
                name: 'faturamento.lotes.detalheguia.auditar',
                params: {
                  arquivoId: model.id,
                  guiaIndex: data.item.index,
                  numeroLote: model.lote,
                },
              })"
            >
              <i class="uil uil-pen icon-size"></i>
            </b-button>
          </b-button-group>
        </template>

      </b-table>
    </div>

    <!-- o v-model não está funcionando direito, o código abaixo vai controlar qnd ele deve ser aberto -->
    <!-- nem no próprio vue-bootstrap está funcionando: https://bootstrap-vue.org/docs/components/modal#prevent-closing -->
    <b-modal
      ref="modal-representacao"
      title="Representação na assinatura digital"
      :hideHeaderClose="true"
      :centered="true"
      ok-only
      v-model="isModalRepresentacaoOpen"
    >
      <div class="filtro-inline">
        <!-- As versões instaladas de bootstrap-vue e bootstrap estão incompatíveis; deixá-las compatível trazia outro bug, então corrigi por css -->
        <b-form-group>
          <b-form-radio-group
            v-model="modalRepresentacao.usarAssinatura"
            :options="optionsRepresentacao"
          ></b-form-radio-group>
        </b-form-group>

        <b-form-group v-if="modalRepresentacao.usarAssinatura == 'S'">
          <b-form-checkbox v-model="modalRepresentacao.usarAssinaturaMedico">Usar assinatura do médico</b-form-checkbox> 
        </b-form-group>

        <f-selectsearch
          v-if="modalRepresentacao.usarAssinatura == 'S' && modalRepresentacao.usarAssinaturaMedico"
          label="Selecione o usuário:"
          name="modalUsuarioId"
          ref="modalUsuarioId"
          v-model="modalRepresentacao.representanteMedicoId"
          :cols="12"
          :searchFunction="pesquisarRepresentanteMedico"
          @select="addRepresentanteMedico"
          labelField="nome"
        />

        <b-form-group v-if="modalRepresentacao.usarAssinatura == 'S'">
          <b-form-checkbox v-model="modalRepresentacao.usarAssinaturaEnf">Usar assinatura do enfermeiro</b-form-checkbox> 
        </b-form-group>

        <f-selectsearch
          v-if="modalRepresentacao.usarAssinatura == 'S' && modalRepresentacao.usarAssinaturaEnf"
          label="Selecione o usuário:"
          name="modalUsuarioId"
          ref="modalUsuarioId"
          v-model="modalRepresentacao.representanteEnfId"
          :cols="12"
          :searchFunction="pesquisarRepresentanteEnf"
          @select="addRepresentanteEnf"
          labelField="nome"
        />
      </div>

      <template #modal-footer>
        <div class="w-100">
          <b-button
            variant="info"
            size="sm"
            class="me-2"
            @click="() => { isModalRepresentacaoOpen = false; }"
          >
            Cancelar
          </b-button>
          <b-button
            variant="success"
            size="sm"
            class="me-2"
            @click="utilizarRepresentante()"
          >
            Baixar
          </b-button>
        </div>
      </template>

    </b-modal>

    <template #footer>
      <button class="btn btn-info px-5" @click="voltar()" type="button">Voltar</button>
    </template>

  </card-form>

</template>

<script>
import CardForm from "@/components/Ui/CardForm";
import FSelectsearch from '@/components/Form/SelectSearch';
import appConfig from "@/app.config";
import { toStringDatetime } from '../../../utils/mixins.datetime'

import { translateStatusLote, translateStatusAuditoria } from '../../../utils/mixins.statuses'
import { trataErro } from "../../../utils/tratar.retornos";
import { getLoteDetalhe, exportarLote, downloadRelatorioAuditoriaFatura, getAllRepresentante } from "../../../services/faturamento.service";
import { ATRIBUICAO } from "../../../services/atribuicao.service";
import { messageError } from '../../../utils/messages';

export default {
  page: {
    title: "Lotes de faturamento",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    CardForm,
    FSelectsearch
  },
  data() {
    return {
      userAttrId: null,
      exportarLote,
      toStringDatetime,
      translateStatusLote,
      translateStatusAuditoria,
      colunas: [
        { key: "codigoGuia", label: "Guia" },
        { key: "nomeBeneficiario", label: "Nome Beneficiário" },
        { key: "codigoBeneficiario", label: "Código Beneficiário" },
        { key: "valorTotal", label: "Valor total" },
        { key: "glosa", label: "Glosa" },
        { key: "valorFinal", label: "Valor Final" },
        { key: "itens", label: "Itens" },
        { key: "itensGlosa", label: "Itens Glosa" },
        { key: "status", label: "Status" },
        { key: "action", label: "Ações", thStyle: 'width: 120px;', thClass: 'text-center', tdClass: 'text-center' },
      ],
      model: {
        convenio: "",
        dataFechamento: "",
        guias: [ ],
        hospital: "",
        id: 0,
        lote: "",
        uploadData: "",
        uploadUsuario: "",
        tipoLote: "",
        originalFilename: "",
      },
      editavel: false,
      optRelatorioAuditoria: {
        glosa: false,
        opme: false,
      },

      isModalRepresentacaoOpen: false,
      optionsRepresentacao: [
        { text: 'Usar assinatura digital', value: 'S' },
        { text: 'Não usar assinatura digital', value: 'N' }
      ],
      modalRepresentacao: {
        usarAssinatura: 'S',

        usarAssinaturaMedico: false,
        usarAssinaturaEnf: false,

        representanteMedicoId: null,
        representanteEnfId: null,
      },
    };
  },
  mounted() {
    this.editavel = this.$route.meta.editavel;

    var user = JSON.parse(localStorage.getItem("user"));
    if (user) this.userAttrId = user.atribuicaoId;

    getLoteDetalhe(this.$route.params.id).then(({ data }) => {
      this.model = data;
    });
  },
  methods: {
    openModalRepresentante() {
        this.isModalRepresentacaoOpen = true
    },

    pesquisarRepresentanteMedico(query) {
      return getAllRepresentante({
        filtro: query,
        atribuicaoIds: [ ATRIBUICAO.MEDICO ],
        ativo: true
      })
    },
    pesquisarRepresentanteEnf(query) {
      return getAllRepresentante({
        filtro: query,
        atribuicaoIds: [ ATRIBUICAO.ENFERMEIRO ],
        ativo: true
      })
    },
    addRepresentanteMedico(representante) {
      if (representante) {
        this.modalRepresentacao.representanteMedicoId = representante.id;
      } else {
        this.modalRepresentacao.representanteMedicoId = null;
      }
    },
    addRepresentanteEnf(representante) {
      if (representante) {
        this.modalRepresentacao.representanteEnfId = representante.id;
      } else {
        this.modalRepresentacao.representanteEnfId = null;
      }
    },

    utilizarRepresentante() {
      let executarDownload = false;
      if (this.modalRepresentacao.usarAssinatura == 'S') {
        executarDownload = true;
        if (!this.modalRepresentacao.usarAssinaturaMedico && !this.modalRepresentacao.usarAssinaturaEnf) {
          messageError(this, 'Selecione o médico e/ou o enfermeiro para a assinatura digital')
          executarDownload = false;
        } 
        if (this.modalRepresentacao.usarAssinaturaMedico && !this.modalRepresentacao.representanteMedicoId) {
          messageError(this, 'Selecione o usuário médico para a assinatura digital')
          executarDownload = false;
        } 
        if (this.modalRepresentacao.usarAssinaturaEnf && !this.modalRepresentacao.representanteEnfId) {
          messageError(this, 'Selecione o usuário enfermeiro para a assinatura digital')
          executarDownload = false;
        }
      } else {
        executarDownload = true;
      }

      if (executarDownload) {
        this.isModalRepresentacaoOpen = false;
        //download
        this.downloadRelatorioAuditoria()
      }
    },

    exportar() {
      exportarLote(this.$route.params.id, this.model.originalFilename)
        .catch((err) => {
          trataErro(err, this)
        })
    },
    downloadRelatorioAuditoria() {
      downloadRelatorioAuditoriaFatura(
        this.$route.params.id,
        this.modalRepresentacao.usarAssinatura == 'S',
        this.modalRepresentacao.representanteEnfId,
        this.modalRepresentacao.representanteMedicoId,
        this.optRelatorioAuditoria.glosa,
        this.optRelatorioAuditoria.opme,
        "relatorio-auditoria-fatura.pdf"
      )
        .catch((err) => {
          trataErro(err, this)
        })
    },
    voltar() {
      if (this.$route.params.backTo) {
        this.$router.push({path: this.$route.params.backTo})
      } else {
        this.$router.push({name: 'faturamento.lotes'})
      }
    }
  }
};
</script>

